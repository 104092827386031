import "./Authenticate.css";

import { useEffect, useState } from "react";

import { SignIn, SignUp, useUser } from "@clerk/clerk-react";
import { useSearchParams, useNavigate } from "react-router-dom";

import routes from "services/routes";

function Authenticate() {
  const { isSignedIn, user } = useUser();
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();

  const afterSignInUrl = queryParameters.get("afterSignInUrl") ?? routes.explore.full;
  const [authType, setAuthType] = useState("signin");

  useEffect(() => {
    var newAuthType = queryParameters.get("authType");
    if (authType == "signin" || authType == "signup") {
      setAuthType(newAuthType || "signin");
    } else {
      setAuthType("signin");
    }
  }, [queryParameters]);

  useEffect(() => {
    if (isSignedIn) {
      navigate(routes.myCareers.short)
    }
  }, [isSignedIn])

  return (
    <div className="my-careers-view">

      {isSignedIn == false && (
        <div className="my-careers-sign-in anim-appear">
          {authType == "signin" && (
            <div className="anim-slide-appear">
              <SignIn signUpUrl={routes.authenticate.full + "?authType=signup"} redirectUrl={afterSignInUrl} />
            </div>
          )}
          {authType == "signup" && (
            <div className="anim-slide-appear">
              <SignUp signInUrl={routes.authenticate.full + "?authType=signin"} />
            </div>
          )}
        </div>
      )}
      {isSignedIn == true && (
        <></>
      )}
    </div>
  );
}

export default Authenticate;
